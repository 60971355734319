import * as React from 'react';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';

import { PageLoaderFallback } from '../../../../layout/PageLoaderFallback';

const Winner = loadable(
  () => import(/* webpackPrefetch: true */ '../../../Valuation'),
  {
    fallback: <PageLoaderFallback />
  }
);

const ValuationTest = loadable(
  () => import(/* webpackPrefetch: true */ '../index'),
  {
    fallback: <PageLoaderFallback />
  }
);

const ValuationRoute: React.FC = () => {
  return (
    <>
      <Route
        exact
        path="/verdivurdering-selge-eiendom"
        component={ValuationTest}
      />
      <Route
        exact
        path="/verdivurdering-selge-eiendom-winner"
        component={Winner}
      />
      <Route
        exact
        path="/verdivurdering-selge-eiendom/:variant"
        component={ValuationTest}
      />
    </>
  );
};

export default ValuationRoute;
